import React from 'react';
import DogForm from '../../components/DogForm/DogForm';

function AddDog() {
  return (
    <div>

      <DogForm />
    </div>
  );
}

export default AddDog;
